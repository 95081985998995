import React, { useState } from 'react';

import SellerConfig from './sellerConfig';
import BuyerConfig from './buyerConfig';

export default function Config() {
    const [tab, setTab] = useState(1);

    return (
        <div>
            <div class="page-tabs">
                <button class={`page-tab ${tab == 1 ? "tab-active" : "tab-inactive"}`} onClick={() => setTab(1)}>
                    Seller
                </button>
                <button class={`page-tab ${tab == 2 ? "tab-active" : "tab-inactive"}`} onClick={() => setTab(2)}>
                    Buyer
                </button>
            </div>
            {tab === 1 ? (<SellerConfig />) : (<BuyerConfig />)}
        </div>
    )
}
