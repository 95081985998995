import React, { useEffect, useState } from 'react'

import RadioButton from '../common/radioButton';
import { useAlert } from '../common/message';
import { IsEmpty, IsUPIID } from '../../Database/validation';
import { eMsg } from '../common/enum';
import { GetDownloadURL, InsertData, SelectData, UpdateData, UploadFile } from '../../Database/db_helper';


export default function SellerConfig() {
    
    const showMsg = useAlert();

    const [logo, setlogo] = useState('');
    const [logofile, setLogoFile] = useState('');
    const [QRfile, setQRFile] = useState('');
    const [Name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [bankName, setBank] = useState('');
    const [accNo, setAccNo] = useState('');
    const [ifscCode, setIfscCode] = useState('');
    const [ConfigId, setConfigId] = useState(null);
    const [design, setDesign] = useState(0);
    const [UPIId, setUPIId] = useState('');
    const [QRCode, setQRCode] = useState('');
    const [IsShowQR, setIsShowQR] = useState(true);
    const [IsShowWatermark, setIsShowWatermark] = useState(true);

    const IsValid = () => {
        if (design === 0) {
            showMsg(eMsg.Info, 'Design is Required.');
            return false;
        }

        if (IsEmpty(Name)) {
            showMsg(eMsg.Info, 'Name is Required.');
            return false;
        }

        if (IsEmpty(bankName)) {
            showMsg(eMsg.Info, 'Bank Name is Required.');
            return false;
        }

        if (IsEmpty(accNo)) {
            showMsg(eMsg.Info, 'Account Number is Required.');
            return false;
        }
        else if (accNo.length < 11) {
            showMsg(eMsg.Info, 'Account Number is invalid.');
            return false;
        }

        if (IsEmpty(ifscCode)) {
            showMsg(eMsg.Info, 'IFSC Code is Required.');
            return false;
        }
        else if (ifscCode.length !== 11) {
            showMsg(eMsg.Info, 'IFSC Code is wrong.');
            return false;
        }

        if (!IsEmpty(UPIId) && !IsUPIID(UPIId)) {
            showMsg(eMsg.Info, 'UPI ID is invalid.');
            return false;
        }

        return true;
    }

    const Save = async () => {
        if (!IsValid()) {
            return;
        }

        let LOGOURL = '';
        if (logofile) {
            const Path = `images/logo/`;
            LOGOURL = UploadFile(logofile, Path);
        }

        let QRURL = '';
        if (QRfile) {
            const Path = `images/qrcode/`;
            QRURL = UploadFile(QRfile, Path);
        }

        const objConfig = {
            Logo: LOGOURL,
            Name: Name,
            BankName: bankName,
            Address: address,
            AccountNo: accNo,
            IFSCCode: ifscCode,
            Design: design,
            UPIID: UPIId,
            QRCode: QRURL,
            ShowQR: IsShowQR,
            ShowWatermark: IsShowWatermark
        }

        if (ConfigId) {
            await UpdateData('Configuration', ConfigId, objConfig);
        }
        else {
            setConfigId(await InsertData('Configuration', objConfig));
        }

        showMsg(eMsg.Success, 'Data Saved Successfully.');
        SetData();
    }

    const SetData = async () => {
        const obj = await SelectData('Configuration', {});
        if (obj.length > 0) {
            setConfigId(obj[0].id);
            setName(obj[0].Name);
            setAddress(obj[0].Address);
            setBank(obj[0].BankName);
            setAccNo(obj[0].AccountNo);
            setIfscCode(obj[0].IFSCCode);
            setDesign(obj[0].Design);
            setUPIId(obj[0].UPIID);
            setIsShowQR(obj[0].ShowQR);
            setIsShowWatermark(obj[0].ShowWatermark);

            const logourl = await GetDownloadURL(obj[0].Logo);
            setlogo(logourl);
            const qrCodeurl = await GetDownloadURL(obj[0].QRCode);
            setQRCode(qrCodeurl);
        }
    }

    useEffect(() => {
        SetData();
    }, []);

    return (
        <div style={{ height: '80vh', overflow: 'auto' }}>
            <div className='form-group'>
                <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                    <label className='control-label float-right mr-15px mt-10px'>Logo <span style={{ color: 'red' }}>*</span></label>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                    <input type='file' onChange={(e) => setLogoFile(e.target.files[0])} className='form-control'></input>
                </div>
            </div>
            <div className='form-group'>
                <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-6 col-xs-6 text-center' >
                    <img src={logo} className='img-logo' alt='logo'></img>
                </div>
            </div>
            <div className='form-group'>
                <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                    <label className='control-label float-right mr-15px'>Design <span style={{ color: 'red' }}>*</span></label>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                    <RadioButton value={design} onChange={setDesign} Items={[[1, 'Design 1'], [2, 'Design 2'], [3, 'Design 3'], [4, 'Design 4']]} />
                </div>
            </div>
            <div className='form-group'>
                <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                    <label className='control-label float-right mr-15px mt-10px'>Name <span style={{ color: 'red' }}>*</span></label>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                    <input type='text' value={Name} onChange={(e) => setName(e.target.value)} className='form-control'></input>
                </div>
            </div>
            <div className='form-group'>
                <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                    <label className='control-label float-right mr-15px mt-30px'>Address</label>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                    <textarea rows={5} value={address} onChange={(e) => setAddress(e.target.value)} className='form-control'></textarea>
                </div>
            </div>
            <div className='form-group'>
                <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                    <label className='control-label float-right mr-15px mt-10px'>Bank Name <span style={{ color: 'red' }}>*</span></label>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                    <input type='text' value={bankName} onChange={(e) => setBank(e.target.value)} className='form-control'></input>
                </div>
            </div>
            <div className='form-group'>
                <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                    <label className='control-label float-right mr-15px mt-10px'>Bank Account Number <span style={{ color: 'red' }}>*</span></label>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                    <input type='text' value={accNo} onChange={(e) => setAccNo(e.target.value)} className='form-control'></input>
                </div>
            </div>
            <div className='form-group'>
                <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                    <label className='control-label float-right mr-15px mt-10px'>IFSC Code <span style={{ color: 'red' }}>*</span></label>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                    <input type='text' value={ifscCode} onChange={(e) => setIfscCode(e.target.value)} className='form-control'></input>
                </div>
            </div>
            <div className='form-group'>
                <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                    <label className='control-label float-right mr-15px mt-10px'>UPI ID</label>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                    <input type='text' value={UPIId} onChange={(e) => setUPIId(e.target.value)} className='form-control'></input>
                </div>
            </div>
            <div className='form-group'>
                <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                    <RadioButton value={IsShowQR} onChange={setIsShowQR} className='form-control' Items={[[true, "Show QRCode"], [false, "Don't Show QRCode"]]} />
                </div>
            </div>
            {IsShowQR && (<div><div className='form-group'>
                <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                    <label className='control-label float-right mr-15px mt-10px'>QR Code</label>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                    <input type='file' onChange={(e) => setQRFile(e.target.files[0])} className='form-control'></input>
                </div>
            </div>
                <div className='form-group'>
                    <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-6 col-xs-6 text-center' >
                        <img src={QRCode} className='img-logo' alt='logo'></img>
                    </div>
                </div>
            </div>)}
            <div className='form-group'>
                <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                    <RadioButton value={IsShowWatermark} onChange={setIsShowWatermark} className='form-control' Items={[[true, "Show Watermark"], [false, "Don't Show Watermark"]]} />
                </div>
            </div>
            <div className='form-group mt-20px'>
                <button className='btn-save mx-auto' onClick={() => Save()}>Save</button>
            </div>
        </div>
    )
}
