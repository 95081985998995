import React, { useEffect, useState } from 'react';
import PopupItem from './popup/popupInvoiceItem';
import { DeleteData, SelectData, sortByField } from '../../Database/db_helper';

export default function Invoice() {
    const [Data, setData] = useState([]);
    const [popup, setPopup] = useState('');

    const refreshData = async () => {
        try {
            const dt = await SelectData('InvoiceItem', {});
            sortByField(dt, 'SerialNo');
            setData(dt);
        }
        catch {

        }
    }

    useEffect(() => {
        refreshData();
    }, []);

    const AddItem = () => {
        setPopup(<PopupItem Type='Add' InvoiceId={0} onClose={ClosePopup} collectionName={'InvoiceItem'} />);
    }

    const EditItem = (Id) => {
        setPopup(<PopupItem Type='Edit' InvoiceId={Id} onClose={ClosePopup} collectionName={'InvoiceItem'} />);
    }

    const DeleteItem = async (Id) => {
        await DeleteData('InvoiceItem', Id);
        refreshData();
    }

    const ClosePopup = () => {
        setPopup('');
        refreshData();
    }

    return (
        <>
            <div>
                <div style={{ margin: '20px 0px' }}>
                    <button className='btn-add' onClick={() => AddItem()} >
                        <i className='fa fa-plus'></i>&nbsp;&nbsp;Add
                    </button>
                </div>
                <div style={{ overflow: 'auto', height: '74vh' }}>
                    <table style={{ width: '100%' }}>
                        <thead style={{ textAlign: 'justify' }}>
                            <tr>
                                <th>SrNo</th>
                                <th>Item</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {Data.map((item, index) => (
                                <tr key={index} style={{ borderBottom: '2px solid gray' }}>
                                    <td>{item.SerialNo}</td>
                                    <td>{item.Name}</td>
                                    <td>
                                        <button className='btn-edit' onClick={() => EditItem(item.id)}>
                                            <i className='fa fa-edit' ></i>&nbsp;Edit
                                        </button>
                                        <button className='btn-delete' onClick={() => DeleteItem(item.id)}>
                                            <i className='fa fa-trash-o' ></i>&nbsp;Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {popup}
        </>
    )
}
